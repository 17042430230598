<template>
	<TransitionRoot appear :show="show" as="template">
		<Dialog as="div" class="relative z-10" @close="onClose">
			<TransitionChild
				as="template"
				enter="duration-300 ease-out"
				enter-from="opacity-0"
				enter-to="opacity-100"
				leave="duration-200 ease-in"
				leave-from="opacity-100"
				leave-to="opacity-0"
			>
				<div class="fixed inset-0 bg-black bg-opacity-25" />
			</TransitionChild>

			<div class="fixed inset-0 overflow-y-auto">
				<div
					class="flex min-h-full items-center justify-center p-4 text-center"
				>
					<TransitionChild
						as="template"
						enter="duration-300 ease-out"
						enter-from="opacity-0 scale-95"
						enter-to="opacity-100 scale-100"
						leave="duration-200 ease-in"
						leave-from="opacity-100 scale-100"
						leave-to="opacity-0 scale-95"
					>
						<DialogPanel
							class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
						>
							<div
								class="absolute right-0 top-0 hidden justify-end pr-1 pt-1 xl:flex"
							>
								<span class="cursor-pointer" @click="onClose">
									<IconTimes
										class="h-5 w-5 text-gray-650 hover:text-blue-400"
									/>
								</span>
							</div>
							<DialogTitle
								v-if="message.title"
								as="h3"
								class="text-center text-lg font-medium leading-6 text-gray-900"
							>
								{{ message.title }}
							</DialogTitle>

							<div :class="[message.error ? 'text-red-400' : '']">
								{{
									message.error
										? message.error
										: message.success
								}}
							</div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script setup>
import {
	TransitionRoot,
	TransitionChild,
	Dialog,
	DialogPanel,
	DialogTitle,
} from '@headlessui/vue'
import { computed } from 'vue'
import { useCarbarStore } from '@/stores/carbar'
import IconTimes from '@/assets/svg/common/ico-times.svg'

const carbar = useCarbarStore()

const message = computed(() => carbar.message)

const show = computed(() => !!carbar.message.error || !!carbar.message.success)

const onClose = () => {
	carbar.clearMessage()
}
</script>
